.dark {
    background-color: #FFEBD0;
    background-image: url('../../assets/landing_background_large.svg');
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 480px) {
    .dark {
        background-image: url('../../assets/landing_background_small.svg');
    }
}
