* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sofia-pro-soft, sans-serif;
}

a {
  color: #1E4CA9;
  text-decoration: none;
}

a:hover {
  font-weight: 700;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 12px 0;
}
