.root {
    height: 100%;
    display: grid;
    grid-template: auto 1fr / 480px 1fr;
}

.filters {
    grid-column: 1 / 3;
    border-bottom: 2px solid #FF6523;
}

.list {
    grid-column: 1;
    overflow: auto;
}

.map {
    grid-column: 2;
}
