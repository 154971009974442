.root {
    padding: 27px 0;
    margin: auto;
    width: 100%;
    max-width: 960px;
    display: flex;
    align-items: center;
}

.root > * {
    flex-grow: 1;
    flex-basis: 0;
}

.logo, .logo > * {
    text-align: center;
    color: #FF673E;
    font-size: 21px;
    font-weight: 400;
    line-height: 40px;
    margin: 0;
}

.logo > *:hover {
    font-weight: 400;
    text-decoration: none;
}

.search {
    text-align: right;
}

@media screen and (max-width: 960px) {
    .root {
        padding: 27px 24px;
    }

    .logo {
        text-align: left;
    }
}
