.locator {
    background-color: white;
    border: 2px solid #1E4CA9;
    border-radius: 50%;
    cursor: pointer;
    margin: 8px;
    padding: 6px;
}

.locator:hover {
    background-color: #EEF0FF;
}

.refresh {
    align-items: center;
    background-color: white;
    border: 2px solid #1E4CA9;
    color: #1E4CA9;
    cursor: pointer;
    display: flex;
    font-family: sofia-pro-soft, sans-serif;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0.8px;
    margin: 8px;
    padding: 8px;
    text-transform: uppercase;
    width: 200px;
}

.refresh:hover {
    background-color: #EEF0FF;
}

.refresh .text {
    margin-left: 8px;
}

.zoom {
    background-color: white;
    border: 2px solid #1E4CA9;
    cursor: pointer;
    margin: 8px;
}

.zoom > * {
    padding: 8px 6px;
}

.zoom > *:first-child {
    border-bottom: 2px solid #1E4CA9;
}

.zoom > *:hover {
    background-color: #EEF0FF;
}