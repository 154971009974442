.root {
    display: inline-block;
    border-bottom: 5px solid #DDE1FF;
    padding: 16px;
}

.root.active {
    background-color: #DDE1FF;
    border-bottom: 5px solid #8E9BFF;
}
