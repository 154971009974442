.root {
    display: block;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

.root.border {
    border: 2px solid #1E4CA9;
}
