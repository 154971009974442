.root {
    width: 270px;
    text-align: left;
    display: inline-block;
    position: relative;
}

@media screen and (max-width: 960px) {
    .root {
        width: 100%;
    }
}
