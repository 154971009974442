.root {
    color: #FF673E;
}

.inner {
    margin: auto;
    max-width: 640px;
    padding: 0 24px;
}

.inner a {
    color: inherit;
    text-decoration: underline;
}

.buttonContainer {
    margin: 48px 0;
}

.slug {
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
}

@media screen and (max-width: 960px) {
    .buttonContainer {
        text-align: center;
    }
}
