.root {
    height: 100%;
    overflow-y: auto;
}

.heading {
    font-size: 16px;
    font-weight: 700;
}

.list {
    font-size: 14px;
    list-style: none;
    padding-left: 0;
}
