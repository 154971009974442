.root {
    overflow: auto;
}

.noResults {
    color: #1E4CA9;
    padding: 24px;
}

.noResultsHeader {
    font-weight: 700;
    margin: 0;
}

.noResultsBody {
    font-weight: 300;
    margin: 0;
}
