.root {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
}

.root:hover {
    font-weight: 700;
}

.radio {
    appearance: none;
    border: none;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/radio_unchecked.svg');
    outline: none;
    margin: 3px 10px 3px 4px;
    flex-shrink: 0;
    background-repeat: no-repeat;
}

.radio:not(:checked):hover {
    background-image: url('../../assets/radio_hover.svg');
}

.radio:checked {
    background-image: url('../../assets/radio_checked.svg');
}

.label {
    flex-grow: 1;
}
