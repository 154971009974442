.root {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
}

.root:hover {
    font-weight: 700;
}

.root.labelFirst {
    flex-direction: row-reverse;
}

.checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/checkbox_unchecked.svg');
    outline: none;
    margin: 3px 10px 3px 4px;
    flex-shrink: 0;
    background-repeat: no-repeat;
}

.checkbox:not(:checked):hover {
    background-image: url('../../assets/checkbox_hover.svg');
}

.checkbox:checked {
    background-image: url('../../assets/checkbox_checked.svg');
}

.label {
    flex-grow: 1;
}
