.root {
    background-color: white;
    border: 2px solid #FF673E;
    border-radius: 10px;
    cursor: pointer;
    width: 270px;
    /* height: 240px; */
    overflow: hidden;
    font-family: sofia-pro-soft, sans-serif;
    color: #1E4CA9;
    position: absolute;
    left: -135px;
}

.image {
    display: block;
    object-fit: cover;
    object-position: center;
    height: 92px;
    width: 100%;
}

.favorite {
    position: absolute;
    top: 12px;
    right: 12px;
}

.text {
    padding: 12px;
}

.name {
    font-weight: 700;
    margin: 0;
}

.description {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    height: 12.6em;
    overflow: hidden;
    line-height: 1.4em;
}

.link {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    display: block;
}

.link:hover {
    text-decoration: underline;
}
