.root {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.back {
    border-bottom: 2px solid #FF6523;
}

.backInner {
    max-width: 960px;
    margin: auto;
    padding: 12px 0;
}

.content {
    flex-grow: 1;
    display: grid;
    grid-template: auto 1fr / 1fr 1fr;
    grid-template-areas:
        "name        info"
        "description info";
    height: 100%;
    width: 100%;
    max-width: 960px;
    margin: auto;
    color: #1E4CA9;
    overflow: auto;
}

.name {
    font-size: 28px;
    font-weight: 700;
    padding: 8px 0;
}

.description {
    grid-area: description;
    overflow: auto;
    padding: 8px 24px 8px 0;
}

.info {
    grid-area: info;
    padding-left: 24px;
    background-image: url('../../assets/background.svg');
    background-size: cover;
    padding-bottom: 24px;
    padding-top: 56px;
}

@media screen and (max-width: 960px) {
    .backInner {
        display: none;
    }
    
    .content {
        display: block;
    }

    .name {
        padding: 0 24px;
    }

    .description {
        padding-left: 24px;
    }

    .info {
        padding-right: 24px;
    }
}
