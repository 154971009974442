.root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
}

.description {
    line-height: 24px;
}
