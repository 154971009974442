.root {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #EEF0FF;
    outline: none;
    cursor: pointer;
    padding: 8px;
}

.root:not(.noHover):hover {
    background-color: #DDE1FF;
}

.root > * {
    vertical-align: middle;
}

.white {
    background-color: white;
}

.transparent {
    background-color: transparent;
}
