.root {
    border: 2px solid #1E4CA9;
    background-color: #EEF0FF;
    position: absolute;
    width: 270px;
    max-height: 350px;
    overflow-y: hidden;
    z-index: 1;
}

.root.fullWidth {
    width: 100%;
}
