.root {
    color: #1E4CA9;
    background-color: #EEF0FF;
    padding: 16px 24px 4px 24px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

.root:hover, .active {
    background-color: #DDE1FF;
}

.title {
    display: block;
}

.selected {
    display: block;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    min-height: 14px
}

.dropdown {
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
    max-height: inherit;
}

.selections {
    margin-top: 14px;
    overflow-y: scroll;
    flex-grow: 1;
    padding-bottom: 16px;
}
