.root {
    padding: 24px 24px 0;
    color: #1E4CA9;
    cursor: pointer;
}

.root.active {
    background-color: #FFEBD0;
}

.inner {
    display: grid;
    grid-template: auto 1fr / 90px 10px 1fr auto;
    height: 108px;
    padding-bottom: 24px;
}

.inner {
    border-bottom: 2px solid #FFEBD0;
}

.image {
    grid-column: 1;
    grid-row: 1 / 3;
}

.name {
    grid-column: 3;
    grid-row: 1;
    font-size: 18px;
    font-weight: 700;
}

.favorite {
    grid-column: 4;
    grid-row: 1;
}

.description {
    font-size: 14px;
    font-weight: 300;
    grid-column: 3 / 5;
    grid-row: 2;
    overflow: hidden;
}
