.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 2;
    background-color: white;
    display: grid;
    grid-template: auto 1fr auto / 1fr;
    color: #1E4CA9;
}

.header {
    background-color: #EEF0FF;
    display: flex;
    padding: 18px 0;
    border-bottom: 2px solid white;
    line-height: 40px;
}

.header > * {
    flex-grow: 1;
    flex-basis: 0;
}

.header > * > * {
    vertical-align: middle;
}

.title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.clear {
    padding-right: 8px;
    text-align: right;
}

.clear > * {
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.6em;
    text-align: center;
    text-transform: uppercase;
    width: min-content;
}

.content {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.filters {
    width: 140px;
    min-width: 140px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 2px solid white;
}

.filter {
    flex-grow: 1;
    flex-basis: 0;
    background-color: #DDE1FF;
    border-bottom: 2px solid white;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filter.active {
    background-color: #EEF0FF;
}

.filterName {
    font-size: 14px;
    font-weight: 700;
}

.selected {
    display: block;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    min-height: 14px
}

.options {
    background-color: #EEF0FF;
    border-bottom: 2px solid white;
    flex-grow: 1;
    overflow: auto;
    padding: 24px;
}

.option {
    display: block;
    border-bottom: 1px solid white;
    padding: 6px 0;
}
