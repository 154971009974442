.root {
    display: flex;
    flex-direction: row;
    color: #1E4CA9;
    background-color: #EEF0FF;
    height: 40px;
}

.root.dark {
    background-color: white;
}

.input {
    flex-grow: 1;
    border: none;
    outline: none;
    background: transparent;
    padding: 8px 8px 8px 16px;
    font: inherit;
    color: inherit;
}
