.root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    border: 2px solid transparent;
    color: white;
    font-weight: 700;
}

.root:disabled {
    color: #1E4CA94D;
    border: 2px solid #1E4CA94D;
    background-color: #EEF0FF;
}

.root:disabled:hover {
    background-color: #EEF0FF;
}

.root.block {
    display: block;
}

.root.fullWidth {
    width: 100%;
}

.primary {
    background-color: #FF673E;
    padding: 20px;
    font-size: 16px;
    min-width: 200px;
}

.primary:hover {
    background-color: #FF420F;
}

.secondary {
    background-color: #1E4CA9;
    letter-spacing: 0.8px;
    padding: 12px;
    font-size: 14px;
    min-width: 200px;
}

.secondary:hover {
    background-color: #182B51;
}

.small {
    background-color: #1E4CA9;
    padding: 12px;
    font-size: 12px;
    min-width: 160px;
}

.small:hover {
    background-color: #182B51;
}

.badge {
    background-color: #8E9BFF;
    border-radius: 50%;
    height: 25px;
    margin-left: 10px;
    padding: 4px;
    text-align: center;
    width: 25px;
}
