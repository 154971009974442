.clear {
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    height: 100%;
    letter-spacing: 0.8px;
    padding: 16px 24px 0;
    text-transform: uppercase;
    text-align: center;
    width: min-content;
}

.clear:hover {
    background-color: #DDE1FF;
}
