.root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.controls {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    background-color: #EEF0FF;
}

.filter {
    margin-right: 24px;
}

.content {
    height: 100%;
    overflow: auto;
}
